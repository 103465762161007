<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn v-if="$route.params.id" @click="editMode = !editMode">
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-row>
            <v-col cols="4">
              <h2 class="mb-3">Sınıf Bilgileri</h2>
              <v-text-field
                class="mb-2"
                v-model="currentSmClass.name"
                label="Sınıf Adı"
                :rules="[
                  (v) => !!v || 'Sınıf adı alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Sınıf adı alanı en az 3 karakterden oluşmalıdır',
                  (v) =>
                    v?.length <= 50 ||
                    'Sınıf adı alanı en fazla 50 karakterden oluşmalıdır',
                ]"
                required
              ></v-text-field>
              <v-text-field
                v-model="currentSmClass.code"
                class="mb-2"
                label="Sınıf Kodu"
                :rules="[
                  (v) => !!v || 'Sınıf kodu alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Sınıf kodu alanı en az 3 karakterden oluşmalıdır',
                  (v) =>
                    v?.length <= 20 ||
                    'Sınıf kodu alanı en fazla 20 karakterden oluşmalıdır',
                ]"
                required
              ></v-text-field>
              <v-text-field
                v-model="currentSmClass.capacity"
                class="mb-2"
                label="Sınıf Kapasitesi"
                :rules="[
                  (v) => !!v || 'Sınıf Kapasitesi alanı boş bırakılamaz',
                  (v) => v >= 10 || 'Sınıf Kapasitesi en az 10 kisi olmalıdır',
                ]"
                required
              ></v-text-field>
              <v-textarea
                v-model="currentSmClass.description"
                class="mb-2"
                label="Açıklama"
              ></v-textarea>
            </v-col>
            <v-col cols="4">
              <h2 class="mb-3">Eğitim Bilgileri</h2>

              <SMEducationSelectbox
                :value="currentSmClass.education_id"
                class="mb-2"
                required
                @update:value="currentSmClass.education_id = $event"
              ></SMEducationSelectbox>

              <v-select
                v-model="currentSmClass.instructor_id"
                label="Öğretmen"
                class="mb-2"
                disabled
              ></v-select>
              <v-select
                v-model="currentSmClass.assistant_id"
                label="Asistan"
                class="mb-2"
                disabled
              ></v-select>
            </v-col>
            <v-col cols="4">
              <h2 class="mb-3">Dönem Bilgileri</h2>
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedStartDate"
                    label="Baslangıç Tarihi"
                    class="mb-2"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Başlangıç tarihi alanı boş bırakılamaz',
                    ]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentSmClass.starts_on"
                  no-title
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedEndDate"
                    label="Bitiş Tarihi"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Bitiş tarihi alanı boş bırakılamaz',
                    ]"
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentSmClass.ends_on"
                  no-title
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <SMRegistrationPeriodsSelectbox
                :value="currentSmClass.registration_period_id"
                class="mb-2"
                required
                @update:value="currentSmClass.registration_period_id = $event"
              ></SMRegistrationPeriodsSelectbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-content-end">
                <v-btn class="mr-3" color="error" @click="reset"> İptal </v-btn>
                <v-btn
                  class="mr-3"
                  color="primary"
                  :disabled="!isFormValid"
                  @click="save"
                >
                  Kaydet
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveAndClose"
                >
                  Kaydet ve Kapat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import Card from "@/view/content/components/Card";
import SMEducationSelectbox from "@/view/content/siliconmade/SM-Education-Selectbox";
import SMRegistrationPeriodsSelectbox from "@/view/content/siliconmade/SM-Registration-Periods-Selectbox.vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_SM_CLASS,
  CREATE_SM_CLASS,
  UPDATE_SM_CLASS,
} from "@/core/services/store/definitions/sm-classes.module";

export default {
  name: "SmClassEdit",
  components: { Card, SMEducationSelectbox, SMRegistrationPeriodsSelectbox },
  data() {
    return {
      editMode: false,
      currentSmClass: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      try {
        this.currentSmClass = Object.assign(
          {},
          await this.getSmClass(this.$route.params.id)
        );
      } catch (error) {
        this.$router.push({ name: "def-sm-classes" });
      } finally {
        this.loading = false;
      }
    }
    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_SM_CLASS, CREATE_SM_CLASS, UPDATE_SM_CLASS]),
    formatDate(date) {
      if (!date) return null;

      return this.$moment(date).format("DD/MM/YYYY");
    },
    async save(replaceRoute = true) {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      if (this.$route.params.id) {
        try {
          const smClass = JSON.parse(JSON.stringify(this.currentSmClass));
          const data = {
            ...smClass,
            id: this.$route.params.id,
          };
          await this.updateSmClass(data);
          this.$toast.success("Sınıf güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const smClass = await this.createSmClass(this.currentSmClass);
          if (replaceRoute) {
            this.$router.push({
              name: "def-sm-classes-edit",
              params: {
                id: smClass.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Sınıf yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-sm-classes" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentSmClass = Object.assign({}, this.smClass);
      } else {
        this.currentSmClass = {};
      }
      this.$router.push({ name: "def-sm-classes" });
    },
  },
  computed: {
    ...mapGetters(["smClass"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Sınıf Düzenle";
        return "Sınıf Detay";
      }
      return "Sınıf Yaratma";
    },
    formattedStartDate() {
      return this.formatDate(this.currentSmClass.starts_on);
    },
    formattedEndDate() {
      return this.formatDate(this.currentSmClass.ends_on);
    },
  },
};
</script>
