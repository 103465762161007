<template>
  <v-select
    :items="registrationPeriods"
    item-value="id"
    class="mb-2"
    item-text="name"
    v-model="selectedRegistrationPeriod"
    label="Kayıt Dönemi"
    :rules="[(v) => !!v || 'Kayıt Dönemi alanı boş bırakılamaz']"
    :loading="loading"
  >
  </v-select>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { FETCH_REGISTRATION_PERIODS } from "@/core/services/store/definitions/registration-periods.module";

export default defineComponent({
  name: "SM-Education-Selectbox",
  props: {
    value: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    await this.fetchRegistrationPeriods();
    this.loading = false;
  },
  data() {
    return {
      selectedRegistrationPeriod: this.value,
      loading: true,
    };
  },
  methods: {
    ...mapActions([FETCH_REGISTRATION_PERIODS]),
  },
  computed: {
    ...mapGetters(["registrationPeriods"]),
  },
  watch: {
    selectedRegistrationPeriod: {
      immediate: true,
      handler(val) {
        this.$emit("update:value", val);
      },
    },
  },
});
</script>
